<div>
  <mat-accordion>

    <!-- tracking -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <span class="title-accord" milestoneTranslate="shipment.tracking.title" [translateValues]="{size: trackingShipments.length}"> 
          Suivi de l'ordre de ramassage ({{trackingShipments.length}})
        </span>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <hr style="margin-top: -7px" />
        <div [ngClass]="trackingShipments.length >= 8 ? 'scroll' : ''">
          <div class="history-tl-container">
            <ul class="tl" style="width: 100%">
              <li
                *ngFor="let track of trackingShipments"
                class="tl-item {{
                  track.codeTracking.codesTracking.includes('ISSUE')
                    ? 'incident'
                    : track.codeTracking.codesTracking
                }}"
              >
                <div class="bloc_tracking">
                  <div class="item-title">
                    <p
                      milestoneTranslate="shipment.codes_tracking.{{track.codeTracking.codesTracking}}"
                      [translateValues]="{
                        user: getUserName(track.user),
                         operator: track.operator,
                         date: (track.shipment.dateReschedule | formatMediumDate), 
                         refMMB:track.middleMileBin?.idMiddleMileBin,
                         refCarrierFile:track.middleMileBin?.idCarrierFile
                         }"
                    > 
                      {{ track.codeTracking.codesTracking }}
                    </p>
                    <span
                      *ngIf="track.codeTracking.codesTracking.includes('ISSUE')"
                    >
                      {{ track.codeTracking.description }}
                    </span>
                    <span
                      *ngIf="track.order"
                    >
                     ordre: <span style='color: #14027a;'><strong> {{ track.order.idOrder}} </strong></span>
                    </span>
                  </div>
                  <div class="timestamp">
                    <span>
                      {{ track.createdDate | formatMediumDatetime }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- notification -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span class="title-accord" milestoneTranslate="shipment.tracking.notifications" [translateValues]="{size: notifications.length}">Messages associés ({{notifications.length}})</span>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <hr style="margin-top: -7px" />
      </ng-template>
    </mat-expansion-panel>

    <!-- files -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span class="title-accord" milestoneTranslate="shipment.tracking.documents" [translateValues]="{size: documents.length}">Documents ({{documents.length}})</span>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <hr style="margin-top: -7px" />        
        <div [ngClass]="documents.length >= 8 ? 'scroll' : ''">
          <div class="history-tl-container">
            <ul class="tl-doc w-100">
              <li *ngFor="let document of documents" class="tl-item-doc">
                <div class="bloc-tracking">
                  <div class="item-title pr-1">
                    <span class="pointer" (click)="document.isOpen = !document.isOpen"> 
                      <span>
                        {{ document.codeTracking.description }}
                      </span>
                      <br>
                      <i *ngIf="!document.isOpen" class="material-icons"> chevron_right </i>
                      <i *ngIf="document.isOpen" class="material-icons"> keyboard_arrow_down </i>
                    </span>
                    <ul *ngIf="document.isOpen">
                      <li *ngFor="let url of splitFileUrls(document.urlFiles)">
                        <div class="container">
                          <div class="row">
                            <div class="col-sm">
                              <span> {{ url.split('/').slice(-1)[0] }} </span>
                            </div>
                            <div class="col-sm-1">
                              <button (click)="download(url)">
                                <i class="fa-solid fa-download"></i>
                              </button>
                            </div>
                            <div class="col-sm-1">
                              <button (click)="open(url)">
                                <i class="fa-solid fa-eye"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="timestamp w-100">
                    <span> 
                      {{ document.createdDate | formatMediumDatetime}}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>


