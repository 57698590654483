<div class="col" (click)="onClick()" style="cursor: pointer;" >
  <div class="card shadow-none border {{ indicator.backgroundClass }}">
    <div class="card-body p-1">
      <div
        class="d-flex flex-wrap align-items-center justify-content-between gap-3"
      >
        <div>
          <p class="fw-medium text-primary-light mb-1">{{ indicator.title }}</p>
          <div class="items-icon">
            <div
              class="w-40-px h-30-px  d-flex justify-content-center align-items-center mr-16"
            >
              <iconify-icon
                [icon]="indicator.icon"
                class="text-{{indicator.backgroundIcon.split('bg-')[1]}} text-2xl mb-0 mx-16"
              >
              </iconify-icon>
            </div>
            <h6 class="mb-0 mb-0 mx-10">{{ indicator.totalCountShipment }} Colis</h6>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-2 align-items-center">
        <div>
          <p class="mb-0">{{ indicator.countShipmentInAddress }}</p>

          <p class="fw-medium text-muted mb-1">Adresse</p>
        </div>

        <div class="h-24-px w-5-px shadow-none border"></div>

        <div>
          <p class="mb-0">{{ indicator.countShipmentInLocker }}</p>

          <p class="fw-medium text-muted mb-1">Consigne</p>
        </div>
      </div>
    </div>
  </div>
</div>
