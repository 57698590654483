import { Injectable } from '@angular/core';
import { ISidebar } from './sidebar.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // Order public members before private members

  // Private members
  sidebarVisible = new BehaviorSubject<boolean>(true);

  // Public members
  sidebarVisible$ = this.sidebarVisible.asObservable();

  iconRelativepath = 'assets/images/svg/sideBare/';

  menus: ISidebar[] = [
    {
      title: 'Tableau de bord',
      icon: this.iconRelativepath + 'table-board.svg',
      type: 'simple',
      routerLink: 'dashboard',
      authorities: '',
    },
    {
      title: 'Ramassage',
      icon: this.iconRelativepath + 'booking.svg',
      routerLink: '/pickup-operations',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Transport',
      icon: this.iconRelativepath + 'transport.svg',
      type: 'dropdown',
      authorities: '',
      submenus: [
        {
          title: 'En départ',
          icon: this.iconRelativepath + 'transport.svg',
          type: 'simple',
          routerLink: '/transport/on-departure',
          authorities: '',
        },
        {
          title: 'En arrivage',
          icon: this.iconRelativepath + 'transport.svg',
          type: 'simple',
          routerLink: '/transport/on-arrival',
          authorities: '',
        },
      ],
    },
    {
      title: 'Livraison',
      icon: this.iconRelativepath + 'booking.svg',
      routerLink: '/order/order-delivery',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Feuille de chargement',
      icon: this.iconRelativepath + 'carrier-file.svg',
      type: 'simple',
      routerLink: '/carrier-file',
      authorities: '',
    },
    {
      title: 'Gestion des colis',
      icon: this.iconRelativepath + 'open-box.svg',
      routerLink: '/shipments',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Suivi des sacs',
      icon: this.iconRelativepath + 'bag.svg',
      routerLink: '/transport/all-sac',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Suivi des opérateurs',
      icon: this.iconRelativepath + 'connected-users.svg',
      type: 'simple',
      routerLink: '/operators',
      authorities: '',
    },
    
   
  ];

  constructor() {}

  toggleSidebar(): void {
    this.sidebarVisible.next(!this.sidebarVisible.value);
  }

  toggle(): any {
    // this.toggled = !this.toggled;
  }

  getSidebarState(): any {
    // return this.toggled;
  }

  isSidebarVisible(): boolean {
    return this.sidebarVisible.value;
  }

  getMenuList(): any {
    return this.menus;
  }
}
