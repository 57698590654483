import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/core/config/websocket.service';
import {
  WebsocketMessage,
  WebsocketTypes,
} from '../shared/notification/models/websocketmessage';
import { NotificationService } from '../shared/notification/notification.service';
import { LayoutService } from './layout.service';
import { SidebarService } from './sidebare/sidebar.service';

@Component({
  selector: 'milestone-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  sidebarVisible = true;
  currentYear: number = new Date().getFullYear();

  private messageSubscription: Subscription | null = null; // Initialize with null

  constructor(
    public sidebarService: SidebarService,
    private wsService: WebsocketService,
    private notificationService: NotificationService,
    private layoutService: LayoutService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sidebarService.sidebarVisible$.subscribe((visible) => {
      this.sidebarVisible = visible;
    });

    // Initialize WebSocket connection
    this.wsService.init();

    // Subscribe to WebSocket messages
    this.messageSubscription = this.wsService
      .onMessage()
      .subscribe((message: WebsocketMessage) => {
        if (
          message.type === WebsocketTypes.NOTIFICATION ||
          message.type === WebsocketTypes.NOTIFICATION_RELOAD ||
          message.type === WebsocketTypes.ERROR
        ) {
          this.notificationService.showNotification(message.title, {
            body: message.message,
            icon: 'assets/images/asset/asset-img1.png',
          });

          this.notificationService.showMessageSuccess(
            'New message: ' + message.message
          );
        }
        if (message.type === WebsocketTypes.NOTIFICATION_RELOAD) {
          // This to listien of type subcription if
          this.layoutService.sendToModule(message);
          this.router.events.subscribe((event: any) => {
            if (event.url.includes(message.action)) {
              this.layoutService.sendToModule(message);
            }
          });
        }
      });
  }

  sendMessage(): void {
    // Send message to a specific destination
    this.wsService.sendMessage('/app/shelving/notify', { orderId: '123' });
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe(); // Clean up to avoid memory leaks
    }
  }

  toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }
}
