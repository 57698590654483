<div class="status-card pointer" >
    <div class="card-header">
      <div class="profile">
        <img src="assets/images/png/unknown.png" alt="Profile Image" class="profile-img">
     
        </div>
        </div>
        <div class="profile-info">
          <div class="row">
            <div class="col-md-6">
          <p><strong style="color: #14027a;">Nom : </strong>{{ operator?.firstname }}</p>
          <p><strong style="color: #14027a;">Prénom : </strong>{{ operator?.lastname }}</p>
          <p><strong style="color: #14027a;">Type :</strong> {{ operator?.userType === 'COLLECTOR' ? 'Ramasseur' : operator?.userType }}</p>
          <p><strong style="color: #14027a;">Contact :</strong><a href='tel:+{{operator?.mobile?.startsWith("00212")? "0" + operator?.mobile?.substring(5): operator?.mobile}}'>{{operator?.mobile?.startsWith("00212")? "0" + operator?.mobile?.substring(5): operator?.mobile}}</a></p>
          </div>
          <div class="col-md-6">
            <p><strong style="color: #14027a;">Colis à ramasser :</strong>  {{ operator?.totalShipmentRamAssigned }} </p>
            <p><strong style="color: #14027a;">Colis à livrer :</strong>  {{ operator?.totalShipmentLivAssigned }} </p>
            <p>   <a [routerLink]="['/order/operator', operator?.idUser]">
              Liste des ordres
            </a></p>
          </div>
        </div>
      </div>
      </div>
