import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'milestone-modal-file',
  templateUrl: './modal-file.component.html',
  styleUrls: ['./modal-file.component.scss']
})
export class ModalFileComponent {
  @Input() title = ''; // Type annotation removed
  @Input() icon: string | null = null;
  @Output() closeModal = new EventEmitter<void>();

 
  constructor( protected activeModal: NgbActiveModal){ }

  close(): void {
    this.activeModal.close();
  }

}
