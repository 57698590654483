import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ShipmentRoutingModule } from './routes/shipment.routing.module';
import { ShipmentListComponent } from './list/shipment-list.component';
import { ShipmentDetailsComponent } from './details/shipment-details.component';
import { IndicatorShipmentCardComponent } from './indicator-card/indicator-shipment-card.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TrackingShipmentComponent } from './tracking/tracking-shipment.component';

@NgModule({
  declarations: [
    ShipmentListComponent,
    ShipmentDetailsComponent,
    IndicatorShipmentCardComponent,
    TrackingShipmentComponent
  ],
  imports: [SharedModule, CommonModule, ShipmentRoutingModule, QRCodeModule],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShipmentModule {}
