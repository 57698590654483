import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { IOrderVM } from '../../order/models/order-vm.model';
import { OrderService } from '../../order/services/order-service.service';
import { IShipmentVM } from '../models/shipment-vm.model';
import { ITrackingShipmentVM } from '../models/tracking-shipment-vm.model';
import { ShipementService } from '../services/shipement.service';

registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'milestone-shipment-details',
  templateUrl: './shipment-details.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  styleUrls: ['./shipment-details.component.scss'],
})
export class ShipmentDetailsComponent implements OnInit {
  idShipment = '';
  isOpen = false;

  trackingShipments: ITrackingShipmentVM[] = [];
  documents: ITrackingShipmentVM[] = [];

  shipment?: IShipmentVM;
  order?: IOrderVM;

  constructor(
    private route: ActivatedRoute,
    private shipementService: ShipementService,
    private notification: NotificationService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.idShipment = this.route.snapshot.paramMap.get('idShipment') ?? '';
    this.getdetailShipment();
    this.getTrackingShipment();
  }

  getdetailShipment(): void {
    this.shipementService.getdetailShipment(Number(this.idShipment)).subscribe({
      next: (response) => {
        this.shipment = response;
        if (this.shipment.idOrder) {
          this.getOrder(this.shipment.idOrder);
        }
      },
      error: (err) => this.notification.showMessageError(err.error.message),
    });
  }

  getOrder(idOrder: string): void {
    this.orderService.getOrderByIdOrder(idOrder).subscribe({
      next: (response) => (this.order = response),
      error: (err) => this.notification.showMessageError(err.error.message),
    });
  }

  getTrackingShipment(): void {
    this.shipementService
      .getTrackingShipment(Number(this.idShipment))
      .subscribe({
        next: (response) => {
          this.trackingShipments = response;
          this.getDocumntsTracking();
        },
        error: (err) => this.notification.showMessageError(err.error.message),
      });
  }

  /* =======================
      private methods
  ===================== */

  private getDocumntsTracking(): void {
    this.documents = this.trackingShipments.filter((tracking) => tracking.urlFiles != null && tracking.urlFiles !== '');
  }
}
