import { Component, OnInit, ViewChild,EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';
import { WebsocketService } from 'src/app/core/config/websocket.service';
import { AppConstant } from '../app-constant';
import { LocationOperator } from 'src/app/modules/location/model/location-operator.model';
@Component({
  selector: 'milestone-location-maps',
  templateUrl: './location-maps.component.html',
  styleUrls: ['./location-maps.component.scss'],
})
export class LocationMapsComponent implements OnInit {
  
  @ViewChild(GoogleMap, { static: false }) googleMap!: GoogleMap;
  operatorPolylines: Map<string, google.maps.Polyline> = new Map();
  operatorMarkers: Map<string, google.maps.marker.AdvancedMarkerElement> = new Map();

  messageSubscription: Subscription | null = null;
  center: google.maps.LatLngLiteral = AppConstant.center;
  zoom = AppConstant.zoom;

  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'cooperative',
    center: this.center,

    zoom: this.zoom,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    mapId: AppConstant.googleMapId,
  };
  @Input() operator = '';
  isconfirmModalOpen = false;
  iconUrl = 'assets/images/svg/delivery.svg';
  @Output() closeModal = new EventEmitter<void>();
  constructor(
    private wsService: WebsocketService,
    protected activeModal: NgbActiveModal,
  ){}

  ngOnInit(): void {
       this.wsService.subscribeToUser('mile.stone');
          this.messageSubscription = this.wsService
            .onLocation()
            .subscribe((location: LocationOperator) => {  
                this.handleLocationUpdate(location);
            
            });
  }

  close(): void {
    this.activeModal.close();
  }


  moveMap(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  onZoom(): void {
    this.zoom = this.googleMap.getZoom() ?? this.zoom;
  }

   /* ====================================
      private methods
======================================== */

private handleLocationUpdate(operator: LocationOperator): void {
  const sender = operator.sender;
  console.log('iddddddd', this.operator);

  if (sender === this.operator.toString()) {
    console.log('sender', sender);
    const position = new google.maps.LatLng(
      +operator.latitude,
      +operator.longitude
    );

    let polyline = this.operatorPolylines.get(sender);

    if (!polyline) {
      polyline = new google.maps.Polyline({
        path: [position], // initialize with the first position
        strokeColor: this.getRandomColor(),
        strokeOpacity: 1.0,
        strokeWeight: 4,
        map: this.googleMap.googleMap!,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 2,
              fillColor: '#3737c3',
              fillOpacity: 1,
              strokeColor: '#3737c3',
            },
            offset: '0',
            repeat: '6px',
          },
        ],
        // Use the googleMap instance properly
      });

      this.operatorPolylines.set(sender, polyline);
    } else {
      const path = polyline.getPath();
      path.push(position);
      // this.operatorPolylines.set(sender, polyline);
    }

    let marker = this.operatorMarkers.get(sender);

    if (!marker) {
      const iconElement = document.createElement('div');
      iconElement.innerHTML = `<img src="${this.iconUrl}" style="width: 35px; height: 35px;" />`;

      marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        map: this.googleMap.googleMap!,
        content: iconElement,
        title: sender,
      });

      this.operatorMarkers.set(sender, marker);
    } else {
      marker.position = position;
    }
  }
}

private getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

}
