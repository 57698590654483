<div class="modal-header">
  <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
</div>

<div class="modal-body">
  <div class="text-center">
    <div class="px-3 py-4 mb-4">
      <div class="text-center shadow-sm">
        <img src="{{ icon }}" alt="info" width="1000" />
      </div>

      <div class="text-center mb-4">
        <div class="mb-0 p-3 badge-pill">
          <p class="h3">
            {{ title }}
          </p>
        </div>
      </div>      
    </div>
  </div>
</div>
