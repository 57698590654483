<div class="row mt-2">
  <div class="col-md-9">
    <div class="card" style="border-left: 9px solid #385888; padding-top: 15px">
      <div class="card-body">
        <div class="bloc_ordres">
          <div class="item_separ">
            <p class="title_details"> Références: </p>
            <span class="value_details" style="color: #14027a">
              <strong>Réf. ordre :</strong>&nbsp;&nbsp;
                <a *ngIf="shipment && shipment.idOrder" [routerLink]="['/order/details', shipment.idOrder]">
                  {{shipment.idOrder | numberWithSpaces }}
                </a>
              </span><br>
              <span *ngIf="shipment && shipment.idMMBin" class="mr-rem-1" style="display:block">
                <span class="value_details" style="color: #14027a"><strong>Réf. sac:</strong></span>&nbsp;&nbsp;
                <a [routerLink]="['/transport/details', shipment.idMMBin]">
                  {{shipment.idMMBin| numberWithSpaces}}
                </a>
               
              </span>
             
              <span class="value_details" style="color: #14027a"><strong>Réf. expédition :</strong></span>&nbsp;&nbsp;{{idShipment | numberWithSpaces}}
              <br />
              <span class="value_details" style="color: #14027a"><strong>Réf code client :</strong></span>&nbsp;&nbsp;
              {{ shipment?.idParcelClient && shipment?.idParcelClient !== "" ? (shipment?.idParcelClient | number : '1.0-0') : '-' }}
            
           
          </div>
          <div class="item_separ">
            <p class="title_details">Expéditeur:</p>
            <span class="value_details">
              <span *ngIf="order && order.companyName !== null"> {{ order.companyName }} </span>
              <br>
              {{ shipment?.firstnameSender !== null ? shipment?.firstnameSender: "-" }}
              {{ shipment?.lastnameSender !== null ? shipment?.lastnameSender: "-" }}
              <br/>
              <a href="tel:+{{ shipment?.gsmSender?.replace('00212', '0') }}"> 
                {{ shipment?.gsmSender?.replace("00212", "0")}}
              </a>
            </span>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
              <div>
                <img src="assets/images/png/delivery.png" alt="" />
              </div>
              <div>
                <p class="title_details"> Opérateur: </p>
                <span
                    class="value_details"
                    *ngIf="order; else notUser"
                    >
                    {{ order.user?.firstname }}
                    {{ order.user?.lastname }}
                    <br />
                    <a href="tel:+{{order.user?.mobile?.replace('00212','0')}}">
                      {{order.user?.mobile?.replace("00212","0")}}
                    </a>
                  </span>
                  <ng-template #notUser> - </ng-template>
              </div>
            </div>
          </div>

          <div class="item_separ">
            <span *ngIf="shipment?.addressCollect !== null; else adresse">
              <div class="content_bloc">
                <div>
                  <img src="assets/images/png/home-icon.png" alt="" />
                </div>
                <div>
                  <p class="title_details">Départ:</p>
                  <span class="value_details">
                    {{ shipment?.cityDeparture?.cityName }} <br />
                    <span class="fsz-14 fw-bold"> adresse: </span> {{ shipment?.addressCollect }} <br>
                    <span class="fsz-14 fw-bold"> info supp: </span> {{ shipment?.addressCollectInfo }}
                    </span>
                </div>
              </div>
            </span>
            <ng-template #adresse>
              <div class="content_bloc">
                <div style="text-align: center">
                  <img src="assets/images/svg/smartlocker.svg" alt="" />
                </div>
                <div>
                  <p class="title_details">Départ:</p>
                  <span class="value_details"> 
                    {{ shipment?.cityDeparture?.cityName }}, <br />
                    {{ shipment?.smartLockerDeparture?.idSmartLocker }}
                    </span>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="item_separ">
            <span *ngIf="shipment?.addressDelivery !== null; else consigne">
              <div class="content_bloc">
                <div>
                  <img src="assets/images/png/home-icon.png" alt="" />
                </div>
                <div>
                  <p class="title_details">Arrivée:</p>
                  <span class="value_details">
                    {{ shipment?.cityArrival?.cityName }}, <br />
                    <span class="fsz-14 fw-medium"> adresse: </span>{{ shipment?.addressDelivery }}
                    <span class="fsz-14 fw-medium"> info supp: </span>{{ shipment?.addressDeliveryInfo }}
                    </span
                  >
                </div>
              </div>
            </span>
            <ng-template #consigne>
              <div class="content_bloc">
                <div style="text-align: center">
                  <img src="assets/images/svg/smartlocker.svg" alt="" />
                </div>
                <div>
                  <p class="title_details">Arrivée:</p>
                  <span class="value_details"
                    >Consigne :
                    {{ shipment?.smartLockerArrival?.idSmartLocker }} <br />
                    {{ shipment?.cityArrival?.cityName }}</span
                  >
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-md-8 card p-3 mt-4 shadow-sm details_shipement"
      style="float: none; margin: auto"
    >
    <div class="row">

      <div class="col-md-9" style="padding-top: 10px;"><h5>Détails de l'expédition : <strong>{{ idShipment | number : "1.0-0"}}</strong></h5>
        <h5 style="margin-top: -15px;margin-bottom: 0px;">Statut :<strong style="line-height: 25px;font-size:18px"> {{ shipment?.currentStatus?.description }}</strong></h5>
      </div>    
      <div class="col-md-3" style="text-align:right">
        <qrcode [qrdata]="idShipment" [width]="100" [errorCorrectionLevel]="'M'" [margin]="0"></qrcode>
      </div>
      </div>
      <hr>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray"> Réference client </span>
        <span class="text-clr">{{ shipment?.idParcelClient | number : "1.0-0" }}</span>
      </div>

      <!--<div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Statut</span>
        <span class="text-clr">{{ shipment?.currentStatus?.description }}</span>
      </div>-->

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Type livraison</span>
        <span class="text-clr">{{
          shipment?.addressDelivery !== null ? "Domicile" : "Consigne"
        }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Paiement à la livraison</span>
        <span class="text-clr">{{ shipment?.cod?.toFixed(0) }} dh</span>
      </div>
      <hr />

      <div
        class="d-flex align-items-center justify-content-between my-1 mt-2 mb-2"
      >
        <span class="text-gray">Trajet</span>
        <span class="text-clr">
          De {{ shipment?.cityDeparture?.cityName }} à
          {{ shipment?.cityArrival?.cityName }}
        </span>
      </div>
      <hr />

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Taille du colis</span>
        <span class="text-clr">{{shipment?.size}}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Ouverture du colis</span>
        <span class="text-clr">
          {{ shipment?.allowOpenParcel !== false ? "Oui" : "Non" }}
        </span>
      </div>
      <!--<div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Nature du colis</span>
        <span class="text-clr">-</span>
      </div>-->

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Assurance</span>
        <span class="text-clr">{{
          shipment?.insureParcel !== false ? "Oui" : "Non"
        }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.insureParcel !== false">
        <span class="text-gray">Colis assuré (valeur déclarée)</span>
        <span class="text-clr">{{
          shipment?.insureParcel !== false ? shipment?.insureValue+" DH" : "-"
        }}</span>
      </div>
      <hr />
      <h5>Expéditeur</h5>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Nom</span>
        <span class="text-clr">
          {{
            shipment?.firstnameSender !== null ? shipment?.firstnameSender : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Prénom</span>
        <span class="text-clr">
          {{
            shipment?.lastnameSender !== null ? shipment?.lastnameSender : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">E-mail</span>
        <span class="text-clr">
          {{ shipment?.emailSender !== null ? shipment?.emailSender : "-" }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">GSM</span>
        <span class="text-clr">
          {{ shipment?.gsmSender?.replace("00212", "0") }}
        </span>
      </div>
      <hr />
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Départ</span>
        <span class="text-clr">{{ shipment?.cityDeparture?.cityName }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">
          {{ shipment?.addressCollect !== null ? "Adresse" : "N° consigne" }}
        </span>
        <span
          class="text-clr"
          *ngIf="shipment?.addressCollect !== null; else consignee"
        >
          <span
            *ngIf="
              (shipment?.addressCollect ?? '').includes('- info supp:');else notsplit">
            {{ (shipment?.addressCollect ?? "").split("- info supp:")[0] }}
          </span>
          <ng-template #notsplit>
            <span class="text-clr">{{ shipment?.addressCollect }}</span>
          </ng-template>
        </span>
        <ng-template #consignee>
          <span class="text-clr">
            {{ shipment?.smartLockerDeparture?.idSmartLocker }}
          </span>
        </ng-template>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressCollect === null">
        <span class="text-gray">Adresse</span>
        <span class="text-clr">{{ shipment?.smartLockerDeparture?.address }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressCollect === null">
        <span class="text-gray">Quartier</span>
        <span class="text-clr">{{ shipment?.smartLockerDeparture?.district }}</span>
      </div>

      <hr />
      <h5>Destinataire</h5>
      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Nom</span>
        <span class="text-clr">
          {{
            shipment?.firstnameRecipient !== null
              ? shipment?.firstnameRecipient
              : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Prénom</span>
        <span class="text-clr">
          {{
            shipment?.lastnameRecipient !== null
              ? shipment?.lastnameRecipient
              : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">E-mail</span>
        <span class="text-clr">
          {{
            shipment?.emailRecipient !== null ? shipment?.emailRecipient : "-"
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">GSM</span>
        <span class="text-clr">
          {{
            shipment?.gsmRecipient !== null
              ? shipment?.gsmRecipient?.replace("00212", "0")
              : "-"
          }}
        </span>
      </div>
      <hr />

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">Ville</span>
        <span class="text-clr"> {{ shipment?.cityArrival?.cityName }} </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2">
        <span class="text-gray">
          {{ shipment?.addressDelivery !== null ? "Adresse" : "N° consigne" }}
        </span>
        <span class="text-clr">
          {{
            shipment?.addressDelivery !== null
              ? shipment?.addressDelivery
              : shipment?.smartLockerArrival?.idSmartLocker
          }}
        </span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressDelivery === null">
        <span class="text-gray">Adresse</span>
        <span class="text-clr">{{ shipment?.smartLockerArrival?.address }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressDelivery === null">
        <span class="text-gray">Quartier</span>
        <span class="text-clr">{{ shipment?.smartLockerArrival?.district }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressDelivery !== null">
        <span class="text-gray">Quartier</span>
        <span class="text-clr">{{ shipment?.districtDelivery ?? '-' }}</span>
      </div>

      <div class="d-flex align-items-center justify-content-between my-1 mb-2" *ngIf="shipment?.addressDelivery !== null">
        <span class="text-gray">Secteur</span>
        <span class="text-clr">-</span>
      </div>
    </div>
  </div>

  <!-- tracking shipment -->
  <div class="col-md-3">
    <milestone-tracking-shipment
    [trackingShipments]="trackingShipments"
    [documents]="documents"
    >
    </milestone-tracking-shipment>
  </div>
</div>
