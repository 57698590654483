<div class="modal-overlay" (click)="close()"></div>
<div class="modal-container">
  <div class="modal-header">
    <div class="header-left">

      <div class="text">Localisation</div>
    </div>
    <div class="header-right">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="modal-content">
    <google-map 
    id="map_location"
    [options]="mapOptions"
    [center]="center"
    [zoom]="zoom"
    (mapClick)="moveMap($event)"
    (zoomChanged)="onZoom()"
    width="760"
    height="515"
    #googleMap
  >
  </google-map>
  </div>
</div>


