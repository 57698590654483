<div class="row mt-3" style="height: 100vh">
  <!-- Operator List (col-md-3 for 25%) -->
  <div
    class="col-md-12 card"
    style="padding: 10px; overflow-y: auto; height: 720px"
  >
    <h6>Opérateur</h6>
    <div class="col-md-3">
      <input
        type="text"
        [(ngModel)]="searchTerm"
        placeholder="Rechercher par nom de l'opérateur ou numéro de téléphone "
        class="form-control"
      />
    </div>
    <div class="row">
      <div *ngFor="let operator of filteredOperators()" class="col-md-3">
        <milestone-operators-card
          [operator]="operator"
          (actionTriggered)="handleOperatorAction(operator)"
          (viewMap)="handleViewMap(operator)"
        >
        </milestone-operators-card>
      </div>
    </div>
  </div>
</div>

<milestone-modal
  [title]="'Détails Opérateur'"
  (closeModal)="handleClose2()"
  *ngIf="isconfirmModalOpen"
>
  <milestone-operator-details [operator]="selectedOperator">
  </milestone-operator-details>
</milestone-modal>

<milestone-modal
  [title]="'Localisation'"
  (closeModal)="handleClose3()"
  *ngIf="isconfirmModalOpen2"
>
  <google-map
    id="map_location"
    [options]="mapOptions"
    [center]="center"
    [zoom]="zoom"
    (mapClick)="moveMap($event)"
    (zoomChanged)="onZoom()"
    width="760"
    height="515"
    #googleMap
  >
  </google-map>
</milestone-modal>
