import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../../../core/config/auth.guard.service';
import { ShipmentListComponent } from '../list/shipment-list.component';
import { ShipmentDetailsComponent } from '../details/shipment-details.component';

const ShipmentRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: ShipmentListComponent,
  },
  {
    path: 'details/:idShipment',
    canActivate: [AuthGuardService],
    component: ShipmentDetailsComponent,
  },

];

@NgModule({
  imports: [RouterModule.forChild(ShipmentRoutes)],
  exports: [RouterModule],
})
export class ShipmentRoutingModule {}
